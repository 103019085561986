<template>
    <b-modal id="ModalPush" title="Push-уведомление всем водителям" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true">
        <b-row>
            <b-col cols="12">
                    <b-row>
                        <b-col cols="12" >
                            <label for="">Сообщение:</label>
                            <b-form-textarea placeholder="Введите текст..." v-model="mail.message"/>
                        </b-col>
                    </b-row>
                <b-row>
                    <b-col cols="2">
                            <b-button :disabled="submitButtonDisabled"  class="mt-2" variant="primary" @click="sendmessage()">Отправить</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent
    },
    props: ['phoneNumber'],
    data(){
        return{
            mail: {
                message: ''
            },
            submitButtonDisabled: false
        }
    },
    methods: {
        sendmessage(){
            this.submitButtonDisabled = true
            this.$http
                .post('push-send-all', this.mail)
                .then(res => {
                    this.$bvModal.hide('ModalPush')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Сообщение отправлено!`,
                        },
                    })
                    this.$emit('refresh')
                    this.mail = {
                        phone: '',
                        message: '' 
                    }
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        }
    },
    watch: {
        phoneNumber(value) {
            if(value) {
                this.mail.phone = value;
            }
        }
    }
}
</script>